<template>
  <div>
    <v-carousel
      v-if="item.imageGallery && item.imageGallery.length > 0"
      height="39vh"
      :show-arrows="false"
      continuous
      hide-delimiter-background
      :hide-delimiters="item.imageGallery && item.imageGallery.length < 2"
    >
      <template v-if="item.imageGallery.length > 1">
        <v-carousel-item
          v-for="(item, i) in item.imageGallery"
          :key="i"
          :lazy-src="item.url ? item.url : item"
          :src="item.url ? item.url : item"
        >
          <v-toolbar
            flat
            dense
            class="transparent"
            style="padding-top: env(safe-area-inset-top)"
          >
            <v-btn
              style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
              class="image-action"
              top
              v-if="!showAsDialog"
              absolute
              icon
              small
              @click.stop="$router.back()"
              ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
            /></v-btn>
            <v-spacer></v-spacer>
            <v-btn
              style="z-index: 1; width: 37px; height: 37px"
              class="image-action mt-5 mr-2"
              small
              icon
              @click.stop="share()"
              ><img src="@/assets/img/share.svg" style="margin-left: -4px"
            /></v-btn>
            <v-btn
              style="z-index: 1"
              class="image-action"
              v-if="showAsDialog"
              small
              icon
              @click.stop="$emit('close-dialog')"
              ><v-icon large color="white">mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              width="37"
              height="37"
              style="z-index: 11; margin-right: -1px"
              class="mt-5"
              v-if="!showAsDialog"
              @click="showLocationDialog = true"
            >
              <v-img
                width="37"
                height="37"
                :src="require('@/assets/img/curved_arrow.svg')"
              ></v-img>
            </v-btn>
          </v-toolbar>
        </v-carousel-item>
      </template>
      <v-carousel-item v-else :src="item.imageGallery[0].url">
        <v-toolbar
          flat
          dense
          class="transparent"
          style="padding-top: env(safe-area-inset-top)"
        >
          <v-btn
            style="z-index: 1; width: 37px; height: 37px; margin-left: -4px"
            class="image-action"
            top
            v-if="!showAsDialog"
            absolute
            icon
            @click.stop="$router.back()"
            width="37"
            height="37"
            ><img src="@/assets/img/arrow-left.svg" style="margin-left: -4px"
          /></v-btn>
          <v-spacer></v-spacer>
          <v-btn
            style="z-index: 1; width: 37px; height: 37px"
            class="image-action mt-5 mr-2"
            small
            icon
            @click.stop="share()"
            ><img src="@/assets/img/share.svg" style="margin-left: -4px"
          /></v-btn>
          <v-btn
            style="z-index: 1"
            class="image-action"
            v-if="showAsDialog"
            small
            icon
            @click.stop="$emit('close-dialog')"
            ><v-icon large color="white">mdi-close</v-icon>
          </v-btn>
          <v-btn
            icon
            width="37"
            height="37"
            style="z-index: 11; margin-right: -1px"
            class="mt-5"
            v-if="!showAsDialog"
            @click="showLocationDialog = true"
          >
            <v-img
              width="37"
              height="37"
              :src="require('@/assets/img/curved_arrow.svg')"
            ></v-img>
          </v-btn>
        </v-toolbar>
      </v-carousel-item>
    </v-carousel>
    <v-card style="border-radius: 32px 32px 0 0 !important" flat class="mt-n8">
      <v-container>
        <v-card-actions class="pt-3 pb-2 pr-4">
          <v-btn
            icon
            v-if="item.weatherConditions == 'FairWeather'"
            class="ml-0"
          >
            <img width="25px" height="25px" src="@/assets/img/sun.svg"
          /></v-btn>
          <v-btn
            icon
            v-else-if="item.weatherConditions == 'BadWeather'"
            class="ml-0"
            ><img width="25px" height="25px" src="@/assets/img/cloud.svg"
          /></v-btn>
          <!-- TODO insert image for both weather conditions -->
          <div
            v-if="openingTimes"
            v-html="openingTimes"
            style="width: 100%; position: absolute; left: 0"
            class="v-html Text-Dark-Text-2---16-Center"
          ></div>
          <v-spacer></v-spacer>
          <div
            class="Text-Dark-Text-2---16-Left-Align"
            v-if="item.priceSegment && !item.price"
          >
            {{ item.priceSegment }}
          </div>
          <div
            class="Text-Dark-Text-2---16-Left-Align"
            style="color: #cacaca !important"
            v-if="item.priceSegment && !item.price"
          >
            {{ "$$$$".slice(0, 4 - item.priceSegment.length) }}
          </div>
          <div v-if="item.price" class="Heading-Dark-H4-18-Left-Align">
            {{ item.currency.code }} {{ item.price }}
          </div>
        </v-card-actions>
        <v-card-title
          v-if="item.header"
          class="pt-1 pb-0 justify-center HeadingDarkH3-22Center"
          style="word-break: break-word"
          >{{ item.header }}</v-card-title
        >
        <v-card-text class="pa-2">
          <template
            v-if="
              $route.name === 'detail' &&
              item.location &&
              displayedAddress &&
              displayedAddress.length > 0
            "
          >
            <v-btn
              class="d-inline-block"
              v-if="$route.name === 'detail'"
              icon
              @click="showLocationDialog = true"
            >
              <img src="@/assets/img/location.svg" />
            </v-btn>
            <open-in-maps
              :lat="lat"
              :lng="lng"
              :value="location"
              @cancel="location = false"
            ></open-in-maps>
            <div
              style="color: #606060; vertical-align: middle; max-width: 65%"
              class="Text-Dark-Text-3---14-Left-Align d-inline-block"
            >
              {{ displayedAddress }}
            </div>
          </template>
          <div
            v-if="distance"
            class="Text-Dark-Text-3---14-Right-Align"
            :class="displayedAddress ? 'd-inline-block' : ''"
            style="position: absolute; right: 20px"
          >
            <span>{{ distance }} km</span>
            <v-img
              class="d-inline-block"
              :class="$store.state.settings.dark ? 'filter' : ''"
              height="25px"
              width="24px"
              :src="require('@/assets/distance.svg')"
            />
          </div>
        </v-card-text>
        <v-card-actions class="px-3 pt-0" v-if="item.category">
          <template v-if="item.category.pictogram">
            <div
              class="mr-2 gradient rounded-circle"
              style="width: 28px; height: 28px; padding: 4px"
            >
              <v-img class="filter" :src="item.category.pictogram" />
            </div>
          </template>

          <span
            :class="!item.category.pictogram ? 'ml-2' : ''"
            class="mt-1 Text-Dark-Text-1---18-Left-Align"
          >
            {{ $t(`categories.${item.category.title}`) }}
          </span>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider class="mx-4"></v-divider>
        <v-card-text
          v-if="item.shortDescription"
          class="Text-Dark-Text-3---14-Left-Align"
          >{{ item.shortDescription }}</v-card-text
        >
        <v-divider class="mx-4"></v-divider>
        <v-card-text
          v-if="!readMoreActivated && item.longDescription"
          class="Text-Dark-Text-2---16-Left-Align px-3"
        >
          {{
            itemDescriptionStripped
              .slice(0, 150)
              .concat(itemDescriptionStripped > 150 ? " ..." : "")
          }}

          <a
            v-if="item.longDescription.length > 150 && !readMoreActivated"
            @click="$emit('read-more')"
            color="primary"
          >
            {{ $t("dashboard.showMore") }}
            <img
              src="@/assets/img/icons-outlines-16-px-read-more.svg"
              class="color-filter Icons-Outlines-16px-Read-more"
            />
          </a>
        </v-card-text>
        <v-card-text
          class="Text-Dark-Text-2---16-Left-Align px-3"
          v-if="readMoreActivated && item.longDescription"
        >
          {{ itemDescriptionStripped }}
          <a color="primary" @click="$emit('read-more')">
            {{ $t("dashboard.showLess") }}
            <img
              src="@/assets/img/icons-outlines-16-px-read-less.svg"
              class="color-filter Icons-Outlines-16px-Read-less"
            />
          </a>
        </v-card-text>
        <v-card-text
          class="v-html Text-Dark-Text-2---16-Center pt-0"
          v-if="item.date && item.time"
          v-html="item.date + ', ' + item.time"
        ></v-card-text>
        <template v-if="item.promoter">
          <v-card-text class="text-center">{{ item.promoter }}</v-card-text>
          <v-divider class="mx-4"></v-divider>
        </template>
        <div class="ticket-tear-line"></div>
        <v-row class="mt-4">
          <v-col class="number-of-tickets col-6 Heading-Color-H5-16-Left-Align">
            {{ $t("tickets.numberOfTickets") }}
          </v-col>

          <v-col class="col-2">
            <v-btn
              class="number-of-tickets-button"
              v-bind:class="{
                deactivated: numberoftickets <= minimumTickets,
              }"
              @click="decreaseNumberOfTickets($event)"
            >
              <div
                class="horizontal-line"
                v-bind:class="{
                  deactivated: numberoftickets <= minimumTickets,
                }"
              />
            </v-btn>
          </v-col>
          <v-col class="col-2 mx-n4 Heading-Dark-H4-18-Center">
            {{ numberoftickets }}
          </v-col>
          <v-col class="col-1">
            <v-btn
              class="number-of-tickets-button"
              v-bind:class="{
                deactivated: numberoftickets >= maximumTickets,
              }"
              @click="increaseNumberOfTickets($event)"
            >
              <div
                class="horizontal-line ml-n1"
                v-bind:class="{
                  deactivated: numberoftickets >= maximumTickets,
                }"
              />
              <div
                class="vertical-line"
                v-bind:class="{
                  deactivated: numberoftickets >= maximumTickets,
                }"
              />
            </v-btn>
          </v-col>
          <v-col class="number-of-tickets col-6">{{
            $t("tickets.totalPrice", {
              price: totalPrice,
              currency: item.currency.code,
            })
          }}</v-col>
        </v-row>
        <v-card-actions v-if="item.currentStock > 0" class="px-3 pt-4">
          <v-btn
            height="36"
            width="139"
            depressed
            color="primary"
            rounded
            class="gradient Heading-White-H4-18-Center"
            >{{ $t("tickets.addToCart") }}</v-btn
          ><v-spacer></v-spacer>
          <v-btn
            height="36"
            width="139"
            depressed
            rounded
            @click="
              $store.dispatch('tickets/purchase', {
                ticket: { ...item, totalPrice },
              })
            "
            color="primary"
            class="gradient Heading-White-H4-18-Center"
            >{{ $t("tickets.getNow") }}</v-btn
          ></v-card-actions
        >
      </v-container>
    </v-card>
  </div>
</template>
<script>
import find from "lodash/find";
import indexOf from "lodash/indexOf";
import filter from "lodash/filter";
import { mapMutations } from "vuex";
import OpenInMaps from "@/components/app/maps/OpenInMaps";
export default {
  data: () => ({
    showLocationDialog: false,
    minimumTickets: 1,
    numberoftickets: 1,
  }),
  components: {
    OpenInMaps,
  },
  props: {
    filteredRecommendations: {
      type: Array,
      validator: (prop) => prop.every((item) => typeof item === "object"),
    },
    ratings: {
      type: Array,
      validator: (prop) => prop.every((item) => typeof item === "object"),
    },
    readMoreActivated: {
      type: Boolean,
      default: false,
    },
    showAsDialog: {
      type: Boolean,
      default: false,
    },
    openingTimes: {
      type: String,
      default: "",
    },
    validSubcategories: {
      type: String,
      default: "",
    },
    displayedAddress: {
      type: String,
      default: "",
    },
    distance: {
      type: String,
      default: "",
    },
  },
  /* Filter to remove html tags in rendered text */
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  computed: {
    item() {
      let item = filter(this.$store.state.tickets.items, (item) => {
        return item.id == this.$route.params.ticketId;
      })[0];
      return item;
    },
    maximumTickets() {
      return parseInt(this.item.maxPerMember) <=
        parseInt(this.item.currentStock)
        ? this.item.maxPerMember
        : this.item.currentStock;
    },
    totalPrice() {
      return parseInt(this.numberoftickets) * parseFloat(this.item.price);
    },
    itemDescriptionStripped() {
      return this.item.longDescription.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  methods: {
    increaseNumberOfTickets(event) {
      if (this.numberoftickets < this.maximumTickets) {
        this.numberoftickets++;
      }
      event.preventDefault();
    },
    decreaseNumberOfTickets(event) {
      if (this.numberoftickets > this.minimumTickets) {
        this.numberoftickets--;
      }
      event.preventDefault();
    },
    share() {
      var options = {
        // message: this.item.header, // not supported on some apps (Facebook, Instagram)
        // subject: this.item.header, // fi. for email
        // files: [' ', ' '], // an array of filenames either locally or remotely
        url: `https://po-co.app/#/detail/${this.item.id}`,
        // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        // iPadCoordinates: '0,0,200,200' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };
      var onSuccess = function (result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      };

      var onError = function (msg) {
        console.log("Sharing failed with message: " + msg);
      };
      try {
        window.plugins.socialsharing.shareWithOptions(
          options,
          onSuccess,
          onError
        );
      } catch (error) {
        // TODO: Add sharing in browser here.
        console.log("Sharing failed: " + error);
      }
    },
    showRecommendations() {
      this.$store.commit(
        "recommendations/SHOW_CURRENT_RECOMMENDATIONS",
        this.item.recommendations
      );
      this.$router.safePush({
        name: "currentLocationRecommendations",
        query: { ...this.$router.currentRoute.query, type: "justForYou" },
      });
    },
    openPage(link) {
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    ...mapMutations({
      setMarker: "map/SET_MARKER",
      setCenter: "map/SET_CENTER",
    }),
    toggle(id) {
      if (
        !find(this.$store.state.profile.data.favorites, (fav) => {
          return fav === id;
        })
      ) {
        if (!this.$store.state.profile.data.favorites) {
          this.$store.state.profile.data.favorites = [];
        }
        this.$store.state.profile.data.favorites.push(id);
      } else {
        this.$store.state.profile.data.favorites.splice(
          indexOf(this.$store.state.profile.data.favorites, id),
          1
        );
      }

      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    },
    find,
  },
};
</script>
<style scoped>
.background {
  background-image: linear-gradient(
    to bottom,
    var(--v-lightpurple-base),
    var(--v-lightpurple-lighten1)
  );
  min-height: 100vh;
}
.ticket {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 13px;
  margin-bottom: 17px;
  padding-bottom: 14px;
  box-shadow: none !important;
  border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  filter: drop-shadow(1px 3px 5px var(--v-shadowonbackground-base));
  position: relative;
  color: black;
  font-size: 16px;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      circle at 0 calc(100% - 134px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ),
    radial-gradient(
      circle at 100% calc(100% - 134px),
      rgba(255, 255, 255, 0) 10px,
      var(--v-background-base) 11px
    ) !important;
  background-position: top left, top right;
}
.ticket-tear-line {
  margin-top: 17px;
  border-top: 6px dotted var(--v-onbackground-darken1);
  margin-left: 15px;
  margin-right: 15px;
}
.thin-divider {
  margin-left: -1px;
  margin-right: -1px;
}
.button-wrapper {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  margin-top: 12px;
}
.button {
  width: 140px !important;
  height: 36px !important;
  padding: 0px !important;
  margin: 0 0 6px 10px;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: none;
}
.image {
  border-radius: 16px 16px 0 0 !important;
  box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.5);
}
.favorite {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 7px;
  padding-right: 6px;
}
.tabslider {
  height: 4px;
  background-image: linear-gradient(
    to right,
    #01a2e9,
    #86d0da 50%,
    #606060 50%
  );
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}
.tabslider.calendar {
  height: 4px;
  background-image: linear-gradient(to left, #86d0da, #01a2e9 50%, #606060 50%);
  border-radius: 2px;
  margin-left: 15px;
  margin-right: 15px;
}
.nextdates {
  height: 67px;
  padding: 0;
  margin: 16px 14px 12px 14px;
  border-radius: 12px;
  box-shadow: 1px 3px 9px 0 var(--v-shadowonbackground-base);
}
.nextdatesitem {
  background-color: #cacaca;
  border-radius: 12px 12px 0 0 !important;
}
.time {
  margin: 6px -7px 4px 16px;
  height: 32px !important;
  width: 73px;
  background-color: white !important;
  border-radius: 12px;
  border: 2px solid #9ea6aa;
  box-shadow: none;
  background-color: var(--v-background-base) !important;
}
.gradient {
  border: 0;
  border-radius: 12px;
  border: 2px solid #9ea6aa;
  box-shadow: none;
  background-color: var(--v-background-base) !important;
}
.gradient {
  border: 0;
  color: var(--v-onprimary-base) !important;
}
.number-of-tickets {
  margin: 0 0 0 18px;
}
.number-of-tickets-button {
  border-radius: 4.2px;
  border: 2px solid #9da6aa;
  box-shadow: none;
  min-width: 35px !important;
  height: 29px !important;
  padding: 0 !important;
  margin: -6px 0px 0 0px;
  background: var(--v-background-base) !important;
}
.horizontal-line {
  width: 10px;
  height: 1px;
  border: solid 1px #9da6aa;
}
.vertical-line {
  width: 1px;
  height: 10px;
  margin-left: -6px;
  border: solid 1px #9da6aa;
}
.deactivated {
  border-color: var(--v-background-darken1);
}
.datepicker {
  border: 0;
}
.darkdatepicker {
  border: 0;
  background-color: var(--v-background-base) !important;
}
</style>
